var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"a7fef5a7cbe12b058a38c826d24713128153dfe3"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'

const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN
const isProd =
  SENTRY_DSN ===
  'https://d5289475d67368caf7459d0b44fdf265@o980452.ingest.sentry.io/4505651929481216'

Sentry.init({
  dsn: SENTRY_DSN,
  replaysSessionSampleRate: isProd ? 1.0 : 0.1,
  replaysOnErrorSampleRate: isProd ? 1.0 : 0.1,
  tracesSampleRate: isProd ? 1.0 : 0.1,
  integrations: [new Sentry.Replay()],
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
})
